import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import router from "@/router";


// Choose MODE (INHOME, DEV , PROD )
const MODE = `${process.env.VUE_APP_MODE}`;
let baseUrl = ""
if (MODE == "DEV") {
    baseUrl = "https://dev-customer-api.dosetech.co/api";
} else if (MODE == "UAT") {
    baseUrl = "https://uat-customer-api.d-dots.com/api";
}

const apiInstance: AxiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        "API-KEY": "ChAtA7Ds40p"
    },
});


apiInstance.interceptors.response.use(response => {
    return response
}, async (error: AxiosError) => {
    return Promise.reject(error);
})



export default apiInstance;
