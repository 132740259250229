
import ResponseData from "@/types/response/Response.interface"
import axios from "../instance/AxiosInstance"
import { TransactionResponse, TransactionResponseDetail, TransactionShippingCost, TransactionShippingCostResponse } from "@/types/transaction/Transaction.interface"
import { TransactionFilter } from "@/types/transaction/Transaction.interface"
import { TransactionDetail, TransactionDetailResponse } from "@/types/transaction/TransactionDetail.interface"
import { SlipInformationResponse, SlipDetail, PaymentMethode, PaymentMethodeResponse, PaymentFormResponse } from "@/types/transaction/TransactionPayment.interface"
import { Bank, BankResponse } from "@/types/master/Bank.interface"
import { Policy, PolicyResponse } from "@/types/master/Policy.interface"
import { StatusChangeResponse } from "@/types/master/StatusChange.interface"
import { Address, AddressInformation, AddressInformationResponse, AddressResponse, BuyerInformation, BuyerInformationResponse } from "@/types/transaction/TransactionAddress.interface"
import { DefaultResponse } from "@/types/master/DefaultResponse.interface"
import { Bankcode, BankCodeResponse } from "@/types/master/Bankcode.interface"
import { DeliveryInformation, DeliveryInformationResponse } from "@/types/master/DeliveryInformation.interface"
import { ContactInformation, ContactInformationResponse } from "@/types/master/ContactInformation.interface"
import { Status, StatusResponse } from "@/types/master/Status.interface"
import { NameTitle, NameTitleResponse } from "@/types/master/NameTitle.interface"

export default class TransactionService {
    async getOrderListsByUserId(filter: TransactionFilter): Promise<TransactionResponseDetail> {
        return await axios.post('/user/transaction', filter)
            .then((res: ResponseData<TransactionResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as TransactionResponseDetail
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async getOrderDetailById(transactionId: string): Promise<TransactionDetail> {
        return await axios.get('/transaction/' + transactionId)
            .then((res: ResponseData<TransactionDetailResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as TransactionDetail
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async onCalculateShippingCost(transactionId: string, buyerInfo: BuyerInformation,): Promise<TransactionShippingCostResponse> {
        return await axios.post('/transaction/' + transactionId + '/calculateShipping', buyerInfo)
            .then((res: ResponseData<TransactionShippingCostResponse>) => {
                if (res.data.result == 1) {
                    return res.data as TransactionShippingCostResponse
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async getOrderUploadSlip(transactionId: number): Promise<SlipDetail> {
        return await axios.get(`/transaction/${transactionId}/transferSlip`)
            .then((res: ResponseData<SlipInformationResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as SlipDetail
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async getBankList(transactionId: number): Promise<Bank[]> {
        return await axios.get('/payment/bankaccount', { params: { transactionId: transactionId } })
            .then((res: ResponseData<BankResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as Bank[]
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async getPolicy(): Promise<Policy> {
        return await axios.get('/privacypolicy')
            .then((res: ResponseData<PolicyResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as Policy
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async onSaveSocialMedia(typeId: number, referId: string, orderId: number) {
        return await axios.post(`/transaction/` + orderId + "/socialInfo", {
            TypeId: typeId,
            ReferenceId: referId
        })
    }

    async onChangeStatus(orderId: number, statusId: number): Promise<StatusChangeResponse> {
        return await axios.post('/transaction/' + orderId + '/status/' + statusId)
            .then((res: ResponseData<StatusChangeResponse>) => {
                return res.data as StatusChangeResponse
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async onSaveBuyerInfo(buyerInfo: BuyerInformation, transactionId: string): Promise<DefaultResponse> {
        return await axios.post(`/transaction/${transactionId}/buyerinfo`, buyerInfo)
            .then((res: ResponseData<DefaultResponse>) => {
                if (res.data.result == 1) {
                    return res.data as DefaultResponse
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async getPaymentList(brandId: number): Promise<PaymentMethode[]> {
        return await axios.get(`/payment/brand/${brandId}`)
            .then((res: ResponseData<PaymentMethodeResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as PaymentMethode[]
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async onUpdatePayment(transactionId: string, paymentMethodId: number): Promise<DefaultResponse> {
        return await axios.post(`/transaction/${transactionId}/payment/${paymentMethodId}`)
            .then((res: ResponseData<DefaultResponse>) => {
                if (res.data.result == 1) {
                    return res.data as DefaultResponse
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async onGetPaymentForm(transactionId: string, paymentMethodId: number, selectedBanking?: Bankcode, isFacebook?: number): Promise<PaymentFormResponse> {
        return await axios.post(`/payment/omise`, {
            transactionId: transactionId,
            type: paymentMethodId,
            bankCode: selectedBanking,
            isFacebook: isFacebook,
        })
            .then((res: ResponseData<PaymentFormResponse>) => {
                if (res.data.result == 1) {
                    return res.data as PaymentFormResponse
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async onUpdateloadSlip(transactionId: string, uploadImage: string[], deleteImage: number[]): Promise<DefaultResponse> {
        return await axios.post(`/payment/uploadslip`, {
            transactionid: transactionId,
            UploadImage: uploadImage,
            deleteImage: deleteImage,
        })
            .then((res: ResponseData<DefaultResponse>) => {
                if (res.data.result == 1) {
                    return res.data as DefaultResponse
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async getBankCodeList(): Promise<Bankcode[]> {
        return await axios.get('/Payment/BankCode')
            .then((res: ResponseData<BankCodeResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as Bankcode[]
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async getBuyerInfoBySocialReferenceId(socialReferenceId: string): Promise<BuyerInformation> {
        return await axios.get(`/user/address/${socialReferenceId}`)
            .then((res: ResponseData<BuyerInformationResponse>) => {

                return res.data.detail as BuyerInformation

            })
    }
    async getDeliveryText(): Promise<DeliveryInformation> {
        return await axios.get(`/staticcontent/DeliveryText`)
            .then((res: ResponseData<DeliveryInformationResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as DeliveryInformation
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })

    }
    async getContact(brandId: number): Promise<ContactInformation[]> {
        return await axios.get(`/staticcontent/contactinfo/${brandId}`)
            .then((res: ResponseData<ContactInformationResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as ContactInformation[]
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async getStatus(): Promise<Status[]> {
        return await axios.get(`/transaction/status`)
            .then((res: ResponseData<StatusResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as Status[]
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async getAddressList(sessionId: string): Promise<Address[]> {
        return await axios.get(`/user/${sessionId}/address/list`)
            .then((res: ResponseData<AddressResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as Address[]
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async getAddressUserDetail(sessionId: string): Promise<BuyerInformation> {
        return await axios.get(`/user/${sessionId}/address`)
            .then((res: ResponseData<BuyerInformationResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as BuyerInformation
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async getNameTitleList(): Promise<NameTitle[]> {
        return await axios.get(`/filter/NameTitle`)
            .then((res: ResponseData<NameTitleResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as NameTitle[]
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async deleteAddress(address: Address, sessionId: string): Promise<DefaultResponse> {
        return await axios.delete(`/user/${sessionId}/address/${address.id}`)
            .then((res: ResponseData<DefaultResponse>) => {
                if (res.data.result == 1) {
                    return res.data as DefaultResponse
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async saveAddress(address?: Address, sessionId?: string): Promise<DefaultResponse> {
        return await axios.post(`/user/${sessionId}/address`, address)
            .then((res: ResponseData<DefaultResponse>) => {
                if (res.data.result == 1) {
                    return res.data as DefaultResponse
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async saveSelfpickAddress(address: string, transactionId: string): Promise<DefaultResponse> {
        return await axios.put(`/transaction/${transactionId}/SelfPicking`, { Location: address })
            .then((res: ResponseData<DefaultResponse>) => {
                if (res.data.result == 1) {
                    return res.data as DefaultResponse
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async updateAddress(address?: Address, sessionId?: string): Promise<DefaultResponse> {
        // return await axios.patch(`/user/${sessionId}/address`, address)
        return await axios.post(`/user/${sessionId}/updateaddress`, address)
            .then((res: ResponseData<DefaultResponse>) => {
                if (res.data.result == 1) {
                    return res.data as DefaultResponse
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async getAddressDropdownList(zipcode?: string): Promise<AddressInformation[]> {
        return await axios.get(`/Transaction/address?zipcode=${zipcode}`)
            .then((res: ResponseData<AddressInformationResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as AddressInformation[]
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
}
